import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useEmployeeProfileData } from '@vyce/core/src/modules/userPreviewModule/hooks/useEmployeeProfileData';
import { getRtwRequest } from '@vyce/core/src/api/legend/checkers';
import { getPassportRequest } from '@vyce/core/src/api/legend/documents';
import {
  createCustomQualificationRequest,
  triggerCheckerRequest,
  uploadCustomQualificationImageRequest,
} from '@vyce/core/src/api/legend/checkers';
import { getCheckersRequest } from '@vyce/core/src/api/checkers';
import { getTagListRequest } from '@vyce/core/src/api/handbook';
import { downloadHealthAndSafetyPDFRequest } from '@vyce/core/src/api/legend/pay';
import { getUserDataRequest } from '@vyce/core/src/api/legend/users';
import { UserPreview } from '@vyce/core/src/views/preview/user/UserPreview';
import { PageLoading } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { LegendUserPreviewProvider } from '@vyce/core/src/contexts/legendUserPreviewProvider';
import { QualificationDialog } from '@vyce/core/src/modules/qualificationDialog';

export const UserPreviewByLegend: React.FC = () => {
  const {
    userPreviewData,
    userPreviewDataLoading,
    userId,
    selectedCompanyId,
    rtw,
    passport,
    contextOptions,
    goToEditView,
    getUserPreviewData,
  } = useEmployeeProfileData({
    getEmployeePreviewRequest: getUserDataRequest,
    getPassportRequest,
    getRtwRequest,
  });
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  if (userPreviewDataLoading)
    return (
      <Box height="100%">
        <PageLoading />
      </Box>
    );

  return (
    <Box padding={isMobile ? 1 : 5}>
      <LegendUserPreviewProvider value={contextOptions}>
        {userPreviewData && (
          <UserPreview
            rtw={rtw}
            passport={passport}
            isLegend={true}
            goToEditView={goToEditView}
            timesheetsLink={`${history.location.pathname}/timesheets`}
            paymentsLink={`${history.location.pathname}/payments`}
            agreementsLink={`${history.location.pathname}/agreements`}
            userPreviewData={userPreviewData}
            showHealthAndSafetyPDFRequest={downloadHealthAndSafetyPDFRequest}
            hasPermissionForCreateQualification
            withAddQualificationModal
            renderQualificationModal={({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
              <QualificationDialog
                isAdmin={false}
                isOpen={isOpen}
                onClose={onClose}
                reRequestData={getUserPreviewData}
                getCheckersRequest={getCheckersRequest}
                triggerCheckerRequest={triggerCheckerRequest}
                createCustomQualificationRequest={createCustomQualificationRequest}
                uploadCustomQualificationImageRequest={uploadCustomQualificationImageRequest}
                getTagListRequest={getTagListRequest}
                type="outside"
                userId={userId}
                selectedCompanyId={selectedCompanyId}
              />
            )}
          />
        )}
      </LegendUserPreviewProvider>
    </Box>
  );
};
