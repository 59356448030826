import { useContext } from 'react';

import Lottie, { Options } from 'react-lottie';
import { Box } from '@material-ui/core';

import { ColorThemeContext } from '../contexts/colorThemeContext';
import * as lightLoaderData from '../assets/animations/loader-light-mode.json';
import * as darkLoaderData from '../assets/animations/loader-dark-mode.json';

interface Props {
  size?: number;
}

function VyceLoader({ size = 166 }: Props) {
  const { colorTheme } = useContext(ColorThemeContext);
  const isDarkTheme = colorTheme === 'dark';

  const styles = {
    transform: 'rotate(-30.5deg)',
    width: size,
    height: size,
    paddingLeft: 17,
    paddingBottom: 48,
  };

  const options: Options = {
    loop: true,
    autoplay: true,
    animationData: isDarkTheme ? darkLoaderData : lightLoaderData,
  };

  return (
    <Box style={styles}>
      <Lottie options={options} />
    </Box>
  );
}

export default VyceLoader;
