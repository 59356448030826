import { axios } from '@vyce/core/src/api';

import { CustomCertificate, CustomQualificationRequestData, Image, OrderByItem } from '../../types';

import {
  ChangeTeamManagersData,
  CreateTeamData,
  GetCompanyTeamsMembersDataRequest,
  GetCompanyTeamsRequest,
  DeleteMemberRequestDTO,
  AddMemberRequestDTO,
  GetUserData,
  GetAgreementsRequest,
  TeamsSyncSettingsDTO,
  GetSyncSettingsData,
  TeamsDataRequest,
  TeamData,
  TeamQualificationWidgetDTO,
} from '../types';

const generateLogoData = (logo: Image): FormData => {
  const bodyFormData = new FormData();

  if (logo?.file) {
    bodyFormData.append('file', logo.file);
  }

  return bodyFormData;
};

export const getCompanyTeamsRequest = ({ companyId, requestData }: GetCompanyTeamsRequest) => {
  return axios.post<{ count: number; items: TeamData[] }>(
    `/teams/companies/${companyId}/search`,
    requestData
  );
};

export const createTeamsRequest = (token: string, companyId: string, data: CreateTeamData) => {
  return axios.post(`/teams/companies/${companyId}`, data);
};

export const updateLogoRequest = (companyId: string, team_id: string, logo: Image) => {
  const data = generateLogoData(logo);
  return axios.post(`/teams/companies/${companyId}/teams/${team_id}/logo`, data);
};

export const deleteLogoRequest = (companyId: string, team_id: string) => {
  return axios.delete(`/teams/companies/${companyId}/teams/${team_id}/logo`);
};

export const getTeamRequest = (companyId: string, teamId: string) => {
  return axios.get(`/teams/companies/${companyId}/teams/${teamId}`);
};

export const updateTeamRequest = (companyId: string, teamId: string, data: CreateTeamData) => {
  return axios.patch(`/teams/companies/${companyId}/teams/${teamId}`, data);
};

export const deleteTeamRequest = (companyId: string, teamId: string) => {
  return axios.delete(`/teams/companies/${companyId}/teams/${teamId}`);
};

export const getTeamMembersRequest = (
  companyId: string,
  teamId: string,
  requestData: GetCompanyTeamsMembersDataRequest
) => {
  return axios.post(`/teams/companies/${companyId}/teams/${teamId}/members/search`, requestData);
};

export const addTeamMembersRequest = (companyId: string, teamId: string, userIds: AddMemberRequestDTO) => {
  return axios.post(`/teams/companies/${companyId}/teams/${teamId}/members`, userIds);
};

export const deleteTeamMembersRequest = (
  companyId: string,
  teamId: string,
  data: DeleteMemberRequestDTO
) => {
  return axios.delete(`/teams/companies/${companyId}/teams/${teamId}/members`, {
    data,
  });
};

export const deleteTeamMembersFromModuleRequest = (companyId: string, data: DeleteMemberRequestDTO) => {
  return axios.delete(`/teams/companies/${companyId}/members`, {
    data,
  });
};

export const changeTeamManagersRequest = (
  companyId: string,
  teamId: string,
  managers: ChangeTeamManagersData
) => {
  return axios.post(`/teams/companies/${companyId}/teams/${teamId}/managers`, managers);
};

export const getNonMembersRequest = (
  companyId: string,
  teamId: string,
  requestData: GetCompanyTeamsMembersDataRequest
) => {
  return axios.post(`/teams/companies/${companyId}/teams/${teamId}/candidates/search`, requestData);
};

export const addAllEmployeesRequest = (token: string, companyId: string, teamId: string) => {
  return axios.post(`/teams/companies/${companyId}/teams/${teamId}/members/add_all_employees`);
};

export const getAllTeamMembersRequest = (
  companyId: string,
  requestData: GetCompanyTeamsMembersDataRequest
) => {
  return axios.post(`/teams/companies/${companyId}/teams/members/search`, requestData);
};

export const getTeamMemberPreviewRequest = ({ userId, companyId }: GetUserData) => {
  return axios.get(`/teams/companies/${companyId}/users/${userId}`);
};

export const getPassportRequest = ({ userId, companyId }: GetUserData) => {
  return axios.get(`/teams/companies/${companyId}/users/${userId}/documents/passport`);
};

export const getRtwRequest = ({ userId, companyId }: GetUserData) => {
  return axios.get(`/teams/companies/${companyId}/users/${userId}/documents/rtw`);
};

export const getHealthAndSafetyPDFByUserIdRequest = ({
  userId,
  companyId,
}: Omit<GetAgreementsRequest, 'token'>) => {
  return axios.post(
    `/teams/companies/${companyId}/users/${userId}/agreements/health_and_safety`,
    {},
    {
      responseType: 'blob',
    }
  );
};

export const getTeamsSyncSettingsRequest = ({ companyId, teamId }: GetSyncSettingsData) => {
  return axios.get<TeamsSyncSettingsDTO>(`/teams/companies/${companyId}/teams/${teamId}/sync_settings`);
};

export const updateTeamsSyncSettingsRequest = ({
  companyId,
  teamId,
  payload,
}: GetSyncSettingsData & {
  payload: TeamsSyncSettingsDTO;
}) => {
  return axios.post<TeamsSyncSettingsDTO>(
    `/teams/companies/${companyId}/teams/${teamId}/sync_settings`,
    payload
  );
};

export const postExportQualifications = ({
  company_id,
  data,
}: {
  company_id: string;
  data: TeamsDataRequest & { order_by?: OrderByItem[] };
}) => {
  return axios.post(`/teams/companies/${company_id}/teams/export_qualifications_to_xlsx`, data, {
    headers: {
      accept: 'application/json',
    },
    responseType: 'blob',
  });
};

export const getTeamQualificationsRequest = (companyId: string) => {
  return axios.post<{ count: number; items: string[] }>(
    `/teams/companies/${companyId}/teams/qualifications`,
    {}
  );
};

export const getTeamsQualifications = ({
  companyId,
  data,
}: {
  companyId: string;
  data: {
    limit: number;
    offset: number;
    team_ids?: string[];
    qualification?: string;
    status?: string;
  };
}) => {
  return axios.post<{ count: number; items: TeamQualificationWidgetDTO[] }>(
    `/dashboards/widgets/teams/companies/${companyId}/custom/qualifications`,
    data
  );
};

export const createQualificationRequest = ({
  parameters,
  userId,
  companyId,
}: {
  parameters: {
    scheme_id: string;
    serial_number: string;
    surname: string;
  };
  userId: string;
  companyId: string;
}) => {
  return axios.post(`/teams/companies/${companyId}/users/${userId}/add_qualification`, parameters);
};

export const createCustomQualificationRequest = ({
  data,
  userId,
  companyId,
}: {
  data: CustomQualificationRequestData;
  userId: string;
  companyId: string;
}) => {
  return axios.post<CustomCertificate>(
    `/teams/companies/${companyId}/users/${userId}/add_custom_qualification`,
    data
  );
};

export const uploadCustomQualificationImageRequest = ({
  file,
  userId,
  certificateId,
  companyId,
}: {
  file: File;
  userId: string;
  certificateId: string;
  companyId: string;
}) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  return axios.post<CustomCertificate>(
    `/teams/companies/${companyId}/users/${userId}/certificates/${certificateId}/document`,
    bodyFormData
  );
};
