import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Layout, Layouts } from 'react-grid-layout';

import { UserDashboard, WidgetItem } from '@vyce/core/src/types';
import { DeviceContext, TeamsDashboardModuleContext } from '@vyce/core/src/contexts';
import { postExportQualifications } from '@vyce/core/src/api/teams';
import { useBooleanState } from '@vyce/core/src/hooks';

import { NotificationContext } from '../../../contexts/notificationContext';
import { useMainDashboardFilter } from '../../../hooks/useMainDashboardFilter';

export const useTeamsDashboardData = () => {
  const [teamsDashboard, setTeamsDashboard] = useState<UserDashboard>();
  const { handleServerError } = useContext(NotificationContext);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isNotifyDialogOpen, openNotifyDialog, closeNotifyDialog] = useBooleanState(false);

  const {
    dashboards,
    first_name,
    companyDataLoading,
    selectedCompanyId,
    teams = [],
    mainDashboardFilters,
    updateUserDashboards,
  } = useContext(TeamsDashboardModuleContext);

  const { selectedTeamOptions, getTeamsForRequest } = useMainDashboardFilter({
    mainDashboardFilters,
  });

  const history = useHistory();

  const { isMobile } = useContext(DeviceContext);

  const selectedTeamIds = useMemo(
    () => getTeamsForRequest(selectedTeamOptions.length ? 'selected' : 'all'),
    [selectedTeamOptions]
  );

  const onWidgetStatusChange = useCallback(
    (systemName: string) => {
      const dashboardWidgets = teamsDashboard?.widgets || [];
      if (dashboardWidgets.length === 0) return;
      const newWidgets: WidgetItem[] = dashboardWidgets.map(item => {
        return item.system_name === systemName ? { ...item, is_active: !item.is_active } : item;
      });

      setTeamsDashboard(value => ({ ...value, widgets: newWidgets }));
    },
    [teamsDashboard?.widgets]
  );

  const updateDashboards = (newWidgetsSettings?: UserDashboard) => {
    if (!teamsDashboard) {
      return;
    }
    updateUserDashboards({
      ...dashboards,
      teams: newWidgetsSettings ?? teamsDashboard,
    });
  };

  const resetDashboards = () => {
    if (teamsDashboard && teamsDashboard.widgets) {
      const restoredDashboard = teamsDashboard.widgets.map(item => {
        return { ...item, is_active: true };
      });
      setTeamsDashboard(value => ({ ...value, widgets: restoredDashboard }));
      updateUserDashboards({
        ...dashboards,
        teams: { ...teamsDashboard, widgets: restoredDashboard },
      });
    }
  };

  const exportQualifications = useCallback(async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      setButtonLoading(true);

      await postExportQualifications({
        company_id: selectedCompanyId,
        data: { team_ids: selectedTeamIds, limit: 3000 },
      });
      openNotifyDialog();
      setButtonLoading(false);
    } catch (e) {
      console.error(e);
      setButtonLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId, teams, selectedTeamIds]);

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    const newWidgetsSettings = { ...teamsDashboard, layouts: newLayouts };
    setTeamsDashboard(newWidgetsSettings);
    updateDashboards(newWidgetsSettings);
  };

  const onWidgetsChangeViaSettings = (newWidget: WidgetItem[]) => {
    const newWidgetsSettings = { ...teamsDashboard, widgets: newWidget };
    setTeamsDashboard(newWidgetsSettings);
  };

  const goToTeamsList = useCallback(() => history.push('/teams/list'), [history]);

  useEffect(() => {
    if (dashboards?.teams) {
      setTeamsDashboard(dashboards.teams);
    }
  }, [dashboards?.teams]);

  return {
    isMobile,
    first_name,
    companyDataLoading,
    originalTeamsDashboard: dashboards?.teams,
    teamsDashboard,
    teams,
    selectedCompanyId,
    buttonLoading,
    isNotifyDialogOpen,
    selectedTeamIds,
    onWidgetsChangeViaSettings,
    updateDashboards,
    resetDashboards,
    closeNotifyDialog,
    exportQualifications,
    onWidgetStatusChange,
    onLayoutChange,
    goToTeamsList,
  };
};
