import React, { useMemo } from 'react';

import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import noop from 'lodash/noop';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import DateFnsUtils from '@date-io/date-fns';
import { FormProvider } from 'react-hook-form';
import { RiListSettingsLine } from 'react-icons/ri';
import { IoEyeOutline } from 'react-icons/io5';
import { CgArrowsExpandRight } from 'react-icons/cg';
import { IoSearchSharp } from 'react-icons/io5';
import { RiDownloadCloud2Line } from 'react-icons/ri';

import { AppSearchInput } from '@vyce/core/src/components/inputs/AppSearchInput';
import { AppKeyboardDatePicker } from '@vyce/core/src/components/inputs/AppKeyboardDatePicker';
import {
  AppDataGrid,
  AppIconButton,
  ButtonTitleWithLoading,
  GridCellWithAvatar,
} from '@vyce/core/src/components';
import { getUKFormattedDate, getYesterday } from '@vyce/core/src/utils/dates';
import { EditSingleTimeLogWorkerDialog } from '@vyce/core/src/components/EditWorkerHoursDialog/EditSingleTimeLogWorkerDialog';
import { ExpandTimeLogsInformation } from '@vyce/core/src/components/EditWorkerHoursDialog/ExpandTimeLogsInformation';
import { CheckOutWorkerDTO } from '@vyce/core/src/api/types';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { ExpandedMenuButton, StyledBadge } from '@vyce/core/src/components/Dashboard/ExpandedMenuButton';
import { FiltersDialog } from '@vyce/core/src/components/Dashboard/FiltersDialog';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { useStyles } from './styles';
import { DailyActivityComponent } from '../../time/ActivityLogs/DailyActivity';
import { InfiniteTable } from '../../../components/InfiniteTable';
import { EnrichedColumnProps } from '../../../components/InfiniteTable/types';
/* Team members that didn't clock out */

export const NotClockedOutWidget = (props: Props & WidgetWrapperProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    methods,
    workers,
    selectedWorker,
    total,
    sortModel,
    cnaViewWorker,
    isMobile,
    dateOfTimeLog,
    logByShift,
    loading,
    isEditSingleTimeLogDialogOpen,
    isExpandTimeLogsInformationDialog,
    isFilterDialogOpen,
    isSmallView,
    isShowSearchField,
    filterSettings,
    isFilterWasActivated,
    downloadButtonLoading,
    isLoadingList,
    resetLoadingList,
    getSharedFile,
    onSubmit,
    restoreFilters,
    openFilterDialog,
    closeFilterDialog,
    handleEditButtonClick,
    handleSortModelChange,
    handleSearchChange,
    handleDateToChange,
    switchFromEditToExpand,
    closeEditSingleTimeLogDialog,
    handleCloseExpandTimeLogsInformationDialog,
    actionsAfterDeletingTimeLog,
    getMembers,
    handlePickTheNextWorker,
    setSmallView,
    setShowSearchFieldTrue,
  } = useWidgetData(props);

  const columns: EnrichedColumnProps<CheckOutWorkerDTO>[] = useMemo(
    () => [
      {
        field: 'first_name',
        headerName: 'Name',
        flex: 1,
        width: 150,
        sortable: true,
        renderCell: row => (
          <GridCellWithAvatar
            avatarUrl={row.photo_url}
            avatarPlaceholder={getAvatar(row.gender)}
            link={cnaViewWorker ? `/time/users/${row.first_name}_${row.user_id}/profile` : ''}
            name={`${row.first_name} ${row.last_name}`}
          />
        ),
      },
      {
        field: 'role',
        headerName: 'Role',
        flex: 0.8,
        width: 100,
        sortable: true,
      },
      {
        field: 'site_name',
        headerName: 'Location',
        flex: 0.8,
        width: 100,
        sortable: true,
      },
      {
        field: 'checked_out',
        headerName: 'Clocked out',
        flex: 0.8,
        width: 100,
        sortable: true,
        valueGetter: row => getUKFormattedDate(row.checked_out),
      },
      {
        field: 'clocked_hours',
        headerName: 'Clocked Hours',
        flex: 0.8,
        width: 100,
        sortable: true,
      },
      {
        field: 'paid_hours',
        headerName: 'Paid Hours',
        flex: 0.7,
        width: 100,
        sortable: true,
      },
      {
        field: 'customCell',
        headerName: 'Add Paid hours',
        sortable: false,
        flex: 0.7,
        width: 120,
        renderCell: row => (
          <Box width="100%" alignItems="center" justifyContent="center">
            <Button
              disabled={!cnaViewWorker}
              startIcon={<AiOutlinePlusCircle size="28px" color={theme.palette.primary.main} />}
              className={classes.buttonWrapper}
              onClick={() => handleEditButtonClick(row as CheckOutWorkerDTO)}
            />
          </Box>
        ),
      },
    ],
    [cnaViewWorker]
  );

  const bigHeaderView = useMemo(
    () => (
      <>
        <AppSearchInput
          onChange={handleSearchChange}
          isExtraSmall
          isBorder
          smallRounding
          expanded={!isMobile}
        />

        <StyledBadge color="primary" variant="dot" invisible={!isFilterWasActivated}>
          <AppIconButton variant="paper" isSmall isBorder onClick={openFilterDialog}>
            <RiListSettingsLine size="20px" color={theme.palette.text.primary} />
          </AppIconButton>
        </StyledBadge>
        <AppIconButton
          variant="paper"
          isSmall
          isBorder
          onClick={getSharedFile}
          disabled={downloadButtonLoading}>
          <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
        </AppIconButton>
      </>
    ),
    [isMobile, handleSearchChange, isFilterWasActivated, downloadButtonLoading, openFilterDialog]
  );

  return (
    <WidgetWrapper
      {...props}
      withExpandButton={!isSmallView}
      setSmallViewStatus={(value: boolean) => {
        setSmallView(value);
      }}
      renderExtraControls={({ handleFullscreenStatusChange }) => (
        <>
          {isSmallView ? (
            <ExpandedMenuButton hideBadge={!isFilterWasActivated}>
              <>
                <Button
                  onClick={handleFullscreenStatusChange}
                  endIcon={<CgArrowsExpandRight size="16px" />}
                  variant="outlined"
                  size="small"
                  hidden={isMobile}>
                  Expanded
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<RiDownloadCloud2Line size="16px" />}
                  onClick={getSharedFile}>
                  Download
                </Button>

                {!isShowSearchField && (
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<IoSearchSharp size="16px" />}
                    onClick={setShowSearchFieldTrue}>
                    Search
                  </Button>
                )}

                <StyledBadge color="primary" variant="dot" invisible={!isFilterWasActivated}>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<RiListSettingsLine size="16px" />}
                    onClick={openFilterDialog}>
                    Filter
                  </Button>
                </StyledBadge>
              </>
            </ExpandedMenuButton>
          ) : (
            bigHeaderView
          )}
        </>
      )}>
      <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={16}>
        <Box width="100%" maxWidth="450px" display="flex" flexDirection="column" gridGap={12}>
          {isSmallView && isShowSearchField && (
            <Box width="200px">
              <AppSearchInput onChange={handleSearchChange} isSmall isBorder expanded={!isMobile} />
            </Box>
          )}
          <Box display="flex" alignItems="center" gridGap={16}>
            <Typography color="primary" className={classes.count}>
              {total} workers
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AppKeyboardDatePicker
                value={dateOfTimeLog}
                onChange={handleDateToChange}
                label=""
                margin="none"
                format="dd/MM/yyyy"
                fullWidth
                maxDate={getYesterday()}
                id="date-picker-dialog"
                disableFuture
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>

        <InfiniteTable
          data={workers}
          columns={columns}
          rowCount={total}
          rowHeight={54}
          height="calc(100% - 1px)"
          pageSize={GRID_PAGE_SIZE}
          isLoadingList={isLoadingList}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onLoadMore={getMembers}
          resetLoadingList={resetLoadingList}
        />

        {logByShift && selectedWorker && isEditSingleTimeLogDialogOpen && (
          <EditSingleTimeLogWorkerDialog
            onSuccess={getMembers}
            userId={selectedWorker.user_id}
            log={logByShift.time_logs[0]}
            open={isEditSingleTimeLogDialogOpen}
            handleClose={closeEditSingleTimeLogDialog}
            date={selectedWorker.checked_out}
            renderAdditionalButton={() => (
              <Button
                size="large"
                onClick={switchFromEditToExpand}
                variant="outlined"
                endIcon={<IoEyeOutline size="20px" />}>
                View
              </Button>
            )}
            renderNextWorkerButton={({ saveFn, loading, disabled }) => (
              <Button
                style={{ width: 152 }}
                size="large"
                disabled={disabled}
                onClick={async () => {
                  await saveFn();
                  handlePickTheNextWorker();
                }}
                color="primary"
                variant="outlined">
                <ButtonTitleWithLoading title="Save, next" loaderVariant="primary" loading={loading} />
              </Button>
            )}
            renderAvatar={() => (
              <GridCellWithAvatar
                avatarUrl={selectedWorker?.photo_url}
                avatarPlaceholder={getAvatar(selectedWorker?.gender)}
                name={`${selectedWorker?.first_name} ${selectedWorker?.last_name}`}
              />
            )}
          />
        )}
        {logByShift && selectedWorker && isExpandTimeLogsInformationDialog && (
          <ExpandTimeLogsInformation
            timeLogByShift={logByShift}
            isOpen={isExpandTimeLogsInformationDialog}
            userId={selectedWorker.user_id}
            onClose={handleCloseExpandTimeLogsInformationDialog}
            onSuccess={actionsAfterDeletingTimeLog}
            firstName={selectedWorker.first_name}
            lastName={selectedWorker.last_name}
            renderAvatar={() => (
              <GridCellWithAvatar
                avatarUrl={selectedWorker?.photo_url}
                avatarPlaceholder={getAvatar(selectedWorker?.gender)}
                name={`${selectedWorker?.first_name} ${selectedWorker?.last_name}`}
              />
            )}>
            <DailyActivityComponent
              companyId={props.selectedCompanyId}
              canViewLocation
              shiftId={logByShift?.time_logs[0]?.shift_id}
              siteName={logByShift?.time_logs[0]?.site_name}
              userId={selectedWorker.user_id}
              date={selectedWorker.checked_out}
              withControls={false}
            />
          </ExpandTimeLogsInformation>
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(noop)}>
            <FiltersDialog
              isOpen={isFilterDialogOpen}
              onClose={closeFilterDialog}
              filterSettings={filterSettings}
              onSubmit={onSubmit}
              restoreFilters={restoreFilters}
              loading={loading}
            />
          </form>
        </FormProvider>
      </Box>
    </WidgetWrapper>
  );
};
