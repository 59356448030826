import React, { useMemo } from 'react';

import { Box, useTheme } from '@material-ui/core';

import { GridCellWithAvatar, AppIconButton } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { RiDownloadCloud2Line } from 'react-icons/ri';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { EnrichedColumnProps } from '../../../components/InfiniteTable/types';
import { TeamMembersWithoutRateDTO } from '../../../api/types';
import { InfiniteTable } from '../../../components/InfiniteTable';

/* Team Members Missing a Rate */

export const TeamMembersMissingRateWidget = (props: Props & WidgetWrapperProps) => {
  const {
    workers,
    isLoadingList,
    sortModel,
    total,
    downloadButtonLoading,
    getMembers,
    resetLoadingList,
    getSharedFile,
    handleSortModelChange,
  } = useWidgetData(props);
  const theme = useTheme();

  const columns: EnrichedColumnProps<TeamMembersWithoutRateDTO>[] = useMemo(
    () => [
      {
        field: 'first_name',
        headerName: 'Name',
        renderCell: row => (
          <GridCellWithAvatar
            avatarUrl={row.photo_url}
            avatarPlaceholder={getAvatar(row.gender)}
            link={`/time/users/${row?.first_name}_${row?.user_id}/settings`}
            name={`${row.first_name} ${row.last_name}`}
          />
        ),
        flex: 0.5,
        width: 100,
        sortable: true,
      },
      {
        field: 'locations',
        headerName: 'Locations',
        flex: 0.9,
        width: 220,
        sortable: false,
        valueGetter: row => row.locations.join(', '),
      },
    ],
    []
  );

  return (
    <WidgetWrapper
      {...props}
      renderExtraControls={() => (
        <>
          <AppIconButton
            variant="paper"
            isSmall
            isBorder
            onClick={getSharedFile}
            disabled={downloadButtonLoading}>
            <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
          </AppIconButton>
        </>
      )}>
      <Box height="100%" width="100%">
        <InfiniteTable
          data={workers}
          columns={columns}
          rowCount={total}
          rowHeight={54}
          height="calc(100% - 1px)"
          pageSize={GRID_PAGE_SIZE}
          isLoadingList={isLoadingList}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onLoadMore={getMembers}
          resetLoadingList={resetLoadingList}
        />
      </Box>
    </WidgetWrapper>
  );
};
