import { useContext, useEffect, useMemo, useState } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';

import { useBooleanState } from '@vyce/core/src/hooks';
import {
  getCheckInWorkersRequest,
  getCheckOutWorkersRequest,
  getCheckOutWorkersRolesRequest,
  getCheckInWorkersRolesRequest,
  getCompaniesWorkersWidgetSharedData,
} from '@vyce/core/src/api/time';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { CheckInWorkerDTO, CheckOutWorkerDTO } from '@vyce/core/src/api/types';
import { useInterval } from '@vyce/core/src/hooks/useInterval';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { TIME_INTERFACE_PERMISSIONS } from '@vyce/core/src/types';
import { useTabFocus } from '@vyce/core/src/hooks/useTabFocus';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { Props } from '../types';
import { generateExcelFileLink } from '../../../../utils';
import { useMainDashboardFilter } from '@vyce/core/src//hooks/useMainDashboardFilter';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';

const defaultLocationOption = { value: 'all', name: 'All Locations' };
const defaultSelectedLocationOption = { value: 'selected', name: 'Selected Locations' };
const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const useWidgetData = <T>({
  selectedCompanyId,
  userPermissions,
  type,
  mainDashboardFilters,
}: Props) => {
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [downloadButtonLoading, setDownloadButtonLoadingTrue, setDownloadButtonLoadingFalse] =
    useBooleanState(false);
  const [isSmallView, setSmallView] = useState(false);
  const [isShowSearchField, setShowSearchFieldTrue] = useBooleanState(false);
  const [roleOptions, setRoleOptions] = useState<{ id: string; name: string }[]>([]);
  const [isFilterDialogOpen, openFilterDialog, closeFilterDialog] = useBooleanState(false);
  const [filters, setFilters] = useState<{ roles: string[] } | null>(null);
  const { isTabFocused } = useTabFocus();
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);

  const {
    sortModel,
    total,
    substring,
    setTotal,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({
    defaultSortModel,
  });

  const {
    isLoadingList,
    data,
    setNewData,
    setInitialLoadingStatusesFalse,
    setInitialLoadingStatusesTrue,
    resetLoadingList,
  } = useInfiniteScroll<T>([]);

  const { selectedLocationOptions, unselectedLocationOptions, getLocationsForRequest } =
    useMainDashboardFilter({
      mainDashboardFilters,
      locationOption: defaultLocationOption,
      selectedLocationOption: defaultSelectedLocationOption,
    });

  const methods = useForm<{ roles: Record<string, boolean>; location: string }>({
    defaultValues: { roles: {}, location: defaultLocationOption.value },
  });

  const roles = methods.watch('roles');
  const location = methods.watch('location');

  const isFilterWasActivated = Object.entries(roles).some(item => item[1] === false);

  const cnaViewWorker = useMemo(
    () => (userPermissions?.length ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.USERS) : true),
    [userPermissions]
  );

  const filterSettings = useMemo(
    () => [
      {
        options: roleOptions,
        title: 'Which roles should be displayed?',
        type: 'roles',
      },
    ],
    [roleOptions]
  );

  const restoreFilters = () => {
    const data = methods.getValues();
    const restoredRoles = Object.keys(data.roles).reduce((sum, curr) => {
      sum[curr] = true;
      return sum;
    }, {} as Record<string, boolean>);

    methods.reset({ roles: restoredRoles });
    handleChangeFilter({ roles: restoredRoles });
  };

  const handleChangeFilter = (data: { roles: Record<string, boolean> }) => {
    const filteredRoles = Object.keys(data.roles).filter(
      item => data.roles[item] || data.roles[item] === undefined
    );
    setFilters({ roles: filteredRoles });
  };

  const getRoles = async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      const request = type === 'clock_in' ? getCheckInWorkersRolesRequest : getCheckOutWorkersRolesRequest;
      const { data } = await request({
        companyId: selectedCompanyId,
        payload: {
          offset: 0,
          limit: 100,
        },
      });
      setRoleOptions(data.items.map(item => ({ id: item, name: item })));
    } catch (e) {
      handleServerError(e);
    }
  };

  const getMembers = async (offset: number = 0) => {
    if (!selectedCompanyId || !isTabFocused) {
      return;
    }
    const itemStatusMap: Record<number, boolean> = setInitialLoadingStatusesFalse(offset, GRID_PAGE_SIZE);

    setLoadingTrue();
    try {
      const request = type === 'clock_in' ? getCheckInWorkersRequest : getCheckOutWorkersRequest;

      const { data } = await request({
        companyId: selectedCompanyId,
        payload: {
          offset: offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatTimeSortModel<CheckInWorkerDTO>(sortModel),
          site_ids: getLocationsForRequest(location),
          worker_substring: substring,
          ...filters,
        },
      });
      setTotal(data.count);
      setNewData({ newData: data.items as T[], offset });
      setInitialLoadingStatusesTrue({ offset, pageSize: GRID_PAGE_SIZE, itemStatusMap });
    } catch (e) {
      handleServerError(e);
    } finally {
      setLoadingFalse();
    }
  };

  const getSharedFile = async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      setDownloadButtonLoadingTrue();
      const res = await getCompaniesWorkersWidgetSharedData({
        companyId: selectedCompanyId,
        target: type === 'clock_in' ? 'checked_in' : 'checked_out',
        payload: {
          offset: 0,
          limit: GRID_PAGE_SIZE,
          order_by: formatTimeSortModel<CheckInWorkerDTO>(sortModel),
          site_ids: getLocationsForRequest(location),
          worker_substring: substring,
          ...filters,
        },
      });
      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    } finally {
      setDownloadButtonLoadingFalse();
    }
  };

  const onSubmit = async () => {
    const data = methods.getValues();
    handleChangeFilter(data);
  };

  const getNewData = () => {
    if (!selectedCompanyId || !isTabFocused) {
      return;
    }

    getMembers();
    getRoles();
  };

  useInterval(getNewData, 60000);

  useEffect(() => {
    getMembers();
  }, [
    selectedCompanyId,
    location,
    sortModel,
    filters,
    substring,
    selectedLocationOptions,
    unselectedLocationOptions,
  ]);

  useEffect(() => {
    getRoles();
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedLocationOptions.length) {
      methods.setValue('location', selectedLocationOptions[0].value);
    } else {
      methods.setValue('location', unselectedLocationOptions[0].value);
    }
  }, [selectedLocationOptions, unselectedLocationOptions]);

  return {
    workers: data,
    total,
    selectedLocationOptions,
    unselectedLocationOptions,
    loading,
    sortModel,
    cnaViewWorker,
    methods,
    filterSettings,
    isFilterDialogOpen,
    isMobile,
    isSmallView,
    isShowSearchField,
    isFilterWasActivated,
    downloadButtonLoading,
    isLoadingList,
    getRoles,
    resetLoadingList,
    getSharedFile,
    openFilterDialog,
    closeFilterDialog,
    onSubmit,
    restoreFilters,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange,
    setSmallView,
    setShowSearchFieldTrue,
  };
};
