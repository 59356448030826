import React, { useContext } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import { CustomizeDashboard, FilterSystem } from '@vyce/core/src/components';
import { WidgetItem } from '@vyce/core/src/types';
import { AppDashboard } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { TimeDashboardModuleContext } from '@vyce/core/src/contexts';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { LiveTimeLocationMap } from '@vyce/core/src/views/time/components';
import { ClockInWidget, ClockOutWidget } from '@vyce/core/src/views/widgets/ClockInOutWidget';
import { WorkersOvertimeWidget } from '@vyce/core/src/views/widgets/WorkersOvertimeWidget';
import { TopPerformingWidget } from '@vyce/core/src/views/widgets/TopPerformingWidget';
import { OnSiteTradesWidget } from '@vyce/core/src/views/widgets/OnSiteTradesWidget';
import { LocationUsersWidget } from '@vyce/core/src/views/widgets/LocationUsersWidget';
import { NotClockedOutWidget } from '@vyce/core/src/views/widgets/NotClockedOutWidget';
import { TeamMembersMissingRateWidget } from '@vyce/core/src/views/widgets/TeamMembersMissingRateWidget';

import { useTimeDashboardData } from './hooks';

export const TimeDashboardModule = () => {
  const horizontalScrollClasses = useHorizontalScrollStyles();

  const {
    mainDashboardFilters,
    teamPermission,
    timePermission,
    widgetComponent,
    onOpenUniversalInviteForm,
    inviteButtonComponent,
    updateMainDashboardFilters,
  } = useContext(TimeDashboardModuleContext);

  const {
    isMobile,
    permissions,
    selectedCompanyId,
    locations,
    timeDashboard,
    name,
    onWidgetsChangeViaSettings,
    onWidgetStatusChange,
    onLayoutChange,
    resetDashboards,
  } = useTimeDashboardData();

  const getWidget = (widget: WidgetItem) => {
    switch (widget.system_name) {
      case 'team_on_site':
        return { component: LiveTimeLocationMap, props: { selectedCompanyId } };
      case 'clocked_in_now':
        return {
          component: ClockInWidget,
          props: {
            mainDashboardFilters,
            selectedCompanyId,
            userPermissions: permissions,
          },
        };

      case 'location_users':
        return { component: LocationUsersWidget, props: { selectedCompanyId, mainDashboardFilters } };
      case 'not_clocked_out_widget':
        return { component: NotClockedOutWidget, props: { selectedCompanyId, locations } };
      case 'clocked_out_today':
        return {
          component: ClockOutWidget,
          props: {
            mainDashboardFilters,
            selectedCompanyId,
            userPermissions: permissions,
          },
        };
      case 'top_performing_team_members_last_week':
        return {
          component: TopPerformingWidget,
          props: { selectedCompanyId, userPermissions: permissions },
        };
      case 'workers_worked_time_overview':
        return { component: WorkersOvertimeWidget, props: { selectedCompanyId } };
      case 'on_site_trades':
        return { component: OnSiteTradesWidget, props: { selectedCompanyId } };

      case 'team_members_missing_rate':
        return { component: TeamMembersMissingRateWidget, props: { selectedCompanyId } };
      default:
        return {
          component: widgetComponent,
          props: { widget, redirectUrl: '/time/timelogs', mainDashboardFilters },
        };
    }
  };

  return (
    <>
      <Box
        className={clsx(
          horizontalScrollClasses.blockWrapper,
          horizontalScrollClasses.blockWithHideScroll,
          horizontalScrollClasses.navBarBlock
        )}>
        {!isMobile && (
          <Box display="flex">
            <Typography
              variant="subtitle1"
              style={{ marginRight: 4, fontWeight: 600, whiteSpace: 'nowrap' }}
              color="secondary">
              Hey {name},
            </Typography>
            <Typography variant="subtitle1" style={{ whiteSpace: 'nowrap' }} color="textSecondary">
              here’s your Time dashboard...
            </Typography>
          </Box>
        )}
        <Box display="flex" gridGap={16}>
          {inviteButtonComponent ? (
            React.createElement(inviteButtonComponent)
          ) : (
            <Button
              onClick={onOpenUniversalInviteForm}
              size="small"
              data-tour="invite"
              startIcon={<AiOutlinePlus />}
              variant="contained"
              color="primary">
              Invite New Members
            </Button>
          )}

          <CustomizeDashboard
            companyId={selectedCompanyId}
            widgetItems={timeDashboard?.widgets || []}
            mainDashboardFiltersFromState={mainDashboardFilters}
            timePermission={timePermission}
            teamPermission={teamPermission}
            updateMainDashboardFilters={updateMainDashboardFilters}
            resetDashboards={resetDashboards}
            onWidgetsChangeViaSettings={onWidgetsChangeViaSettings}
          />
        </Box>
      </Box>

      <Box style={{ marginRight: '-24px', marginLeft: '-24px' }}>
        <AppDashboard
          layouts={timeDashboard?.layouts}
          onLayoutChange={onLayoutChange}
          widgets={timeDashboard?.widgets || []}
          getWidget={getWidget}
          onWidgetStatusChange={onWidgetStatusChange}
        />
      </Box>
    </>
  );
};
