import { createContext, useContext, useEffect, useState } from 'react';

import noop from 'lodash/noop';

import { AI_TOOL_ACTIVATED } from '../constants';
import { AIChatFile, AIChatMessage, AIChatRole, User } from '../../../types';
import { useBooleanState } from '../../../hooks';
import {
  askAIRequest,
  createThreadRequest,
  deleteThreadRequest,
  speechToTextRequest,
  askAiAssistantRequest,
} from '../../../api/ai';
import { saveToLS } from '../../../utils/local-storage';
import { getPersonalizedMessage } from '../utils';
import { NotificationContext } from '../../../contexts/notificationContext';
import { AskAIAssistantRequest } from '../../../api/types';
import { useFunctions } from '../hooks/useFunctions';

interface Data {
  messages: AIChatMessage[];
  loading: boolean;
  isAIActivated: boolean;
  speechToText: (audioFile: string) => void;
  setFiles: Function;
  files: AIChatFile[];
  activateAIChat: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  setIsAIActivated: (status: boolean) => void;
  addUserMessage: (message: string, fileId?: string) => void;
  ask: (messages: AIChatMessage[]) => void;
  user?: User;
  setMessages: (messages: AIChatMessage[]) => void;
  createThread: (messages: AIChatMessage[]) => void;
  deleteThread: (threadId: string) => void;
  threadId: string;
  askAiAssistant: (request: AskAIAssistantRequest) => void;
}

const initialData: Data = {
  messages: [],
  loading: false,
  isAIActivated: false,
  speechToText: noop,
  activateAIChat: noop,
  addUserMessage: noop,
  setIsAIActivated: noop,
  ask: noop,
  setLoadingTrue: noop,
  setLoadingFalse: noop,
  setFiles: noop,
  files: [],
  user: undefined,
  setMessages: noop,
  createThread: noop,
  deleteThread: noop,
  threadId: '',
  askAiAssistant: noop,
};

export const AIChatContext = createContext(initialData);

interface Props {
  children: any;
  user?: User;
  model?: string;
  assistantId?: string;
  companyId?: string;
}

function AIChatContextProvider({ children, user, model, assistantId, companyId }: Props) {
  const { handleServerError } = useContext(NotificationContext);
  const [messages, setMessages] = useState<AIChatMessage[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [isAIActivated, setIsAIActivated] = useState<boolean>(false);
  const [personalMessage, setPersonalMessage] = useState<string>('');
  const [files, setFiles] = useState<AIChatFile[]>([]);
  const [threadId, setThreadId] = useState<string>('');
  const { processFunction } = useFunctions();

  const ask = async (messages: AIChatMessage[]): Promise<any> => {
    try {
      setLoadingTrue();
      const res = await askAIRequest({
        messages: [{ role: 'assistant', content: personalMessage }, ...messages],
        model,
        assistantId,
      });
      const systemMessage = res.data.body?.message;

      if (systemMessage?.tool_calls) {
        const toolCall = systemMessage.tool_calls[0];
        const result = await processFunction(toolCall, companyId);
        messages.push(systemMessage);
        messages.push({
          role: 'tool',
          tool_call_id: toolCall.id,
          content: result.toString(),
        });
        return ask([...messages]);
      }

      if (systemMessage) {
        setMessages(value => [...value, systemMessage]);
      }
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const askAiAssistant = async ({ message, assistantId, threadId }: AskAIAssistantRequest) => {
    try {
      setLoadingTrue();
      const res = await askAiAssistantRequest({ message, assistantId, threadId });
      const systemMessage = res.data.body?.message;
      if (systemMessage) {
        setMessages(value => [...value, systemMessage]);
      }
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const createThread = async (messages: AIChatMessage[] = []) => {
    try {
      const res = await createThreadRequest(messages);
      setThreadId(res.data.body.id);
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteThread = async (threadId: string) => {
    try {
      await deleteThreadRequest(threadId);
    } catch (e) {
      handleServerError(e);
    }
  };

  const addUserMessage = async (message: string, fileId?: string) => {
    const newMessage: AIChatMessage = {
      role: 'user' as AIChatRole,
      content: message,
    };
    if (fileId) {
      newMessage.attachments = [{ file_id: fileId, tools: [{ type: 'file_search' }] }];
    }
    setMessages(value => [...value, newMessage]);
  };

  const speechToText = async (audioFile: string) => {
    try {
      setLoadingTrue();
      const res = await speechToTextRequest(audioFile);
      const message = res.data.body;
      if (message) {
        addUserMessage(message);
      }
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const activateAIChat = () => {
    setIsAIActivated(true);
    saveToLS(AI_TOOL_ACTIVATED, 'true');
  };

  useEffect(() => {
    if (user) {
      const message = getPersonalizedMessage(user);
      setPersonalMessage(message);
    }
  }, [user]);

  const value = {
    loading,
    messages,
    speechToText,
    isAIActivated,
    activateAIChat,
    addUserMessage,
    setIsAIActivated,
    ask,
    setLoadingTrue,
    setLoadingFalse,
    files,
    setFiles,
    user,
    setMessages,
    createThread,
    deleteThread,
    threadId,
    askAiAssistant,
  };

  return <AIChatContext.Provider value={value}>{children}</AIChatContext.Provider>;
}

export default AIChatContextProvider;
