import {
  getCheckInWorkersRequest,
  getCheckOutWorkersRequest,
  getLookupSiteWorkersRequest,
} from '../../../api/time';
import { AIChatToolCall } from '../../../types';

export enum FunctionName {
  GET_TIME_USER_INFO = 'get_time_user_info',
  GET_CLOCKED_IN_INFO = 'get_clocked_in_info',
  GET_CLOCKED_OUT_INFO = 'get_clocked_out_info',
}

export const useFunctions = () => {
  const processFunction = async (toolCall: AIChatToolCall, companyId?: string): Promise<string> => {
    const {
      function: { name, arguments: args },
    } = toolCall;
    const argsObj = JSON.parse(args);

    const clockDataArgs = {
      companyId: companyId || '',
      payload: { limit: 1000, offset: 0, worker_substring: argsObj.name },
    };

    switch (name) {
      case FunctionName.GET_TIME_USER_INFO:
        if (!companyId) {
          return '';
        }
        const siteWorkersRes = await getLookupSiteWorkersRequest({
          companyId,
          payload: { limit: 10, offset: 0, substring: argsObj.name },
        });
        return JSON.stringify(siteWorkersRes.data);
      case FunctionName.GET_CLOCKED_IN_INFO:
        if (!companyId) {
          return '';
        }
        const clockedInRes = await getCheckInWorkersRequest(clockDataArgs);
        return JSON.stringify(clockedInRes.data);
      case FunctionName.GET_CLOCKED_OUT_INFO:
        if (!companyId) {
          return '';
        }
        const clockedOutRes = await getCheckOutWorkersRequest(clockDataArgs);
        return JSON.stringify(clockedOutRes.data);
      default:
        return '';
    }
  };

  return { processFunction };
};
