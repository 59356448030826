import { useCallback, useContext, useEffect, useState, useMemo } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import {
  AddTimeWorkerData,
  Company,
  Employee,
  AddMemberForm,
  FilterSection,
  Tag,
  TagGroups,
} from '@vyce/core/src/types';
import { useBooleanState, useDebounceValue } from '@vyce/core/src/hooks';
import {
  addSiteWorkerRequest,
  getLookupSiteWorkersRequest,
  removeWorkerFromAllLocationsRequest,
} from '@vyce/core/src/api/time';
import { GetSiteWorkersRequestPayload, WorkerLookupDTO } from '@vyce/core/src/api/types';
import { fetchTagsRequest } from '@vyce/core/src/api/handbook';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { isJson, isNil } from '@vyce/core/src/utils';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';
import { USER_STATUSES } from '../constants';

interface Props {
  siteId?: string;
  isSitePage?: boolean;
  selectedCompany?: Company;
}

const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'asc' }];

export const useLocationUsersData = ({ siteId, isSitePage, selectedCompany }: Props) => {
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false);
  const [roles, setRoles] = useState<Tag[]>([]);
  const { handleServerError } = useContext(NotificationContext);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<any>();
  const [workers, setWorkers] = useState<WorkerLookupDTO[]>([]);
  const [isAddMemberDialogOpen, openAddMemberDialog, closeAddMemberDialog] = useBooleanState(false);

  const {
    sortModel,
    offset,
    substring,
    setOffset,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({ defaultSortModel });

  const history = useHistory();

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const filtersSections: FilterSection[] = useMemo(
    () => [
      {
        title: 'User status',
        expanded: true,
        filters: [
          {
            type: 'radio',
            label: 'User status',
            field: 'user_status',
            values: [USER_STATUSES.all, USER_STATUSES.checked_in, USER_STATUSES.not_checked_out],
            defaultValue: '',
          },
        ],
      },
      {
        title: 'Worker Profiles',
        expanded: true,
        filters: [
          {
            type: 'select',
            multiple: false,
            label: 'Job Role',
            field: 'role',
            values: ['', ...roles],
            defaultValue: '',
          },
        ],
      },
    ],
    [roles]
  );

  const removeWorkerFromAllLocations = async (userId: string) => {
    if (!selectedCompanyId) return;

    try {
      await removeWorkerFromAllLocationsRequest(selectedCompanyId, userId);
      getMembers();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchJobs = async () => {
    try {
      const resRoles = await fetchTagsRequest({ group: TagGroups.ROLE, short: false });
      const transformedRoles = resRoles.data.items?.map((i: Tag) => i.name);
      setRoles(['-', ...transformedRoles]);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (filters: any) => {
    history.push({
      pathname: history.location.pathname,
      search: generateQueryParamsFromObj(filters),
    });
  };

  const getMembers = useCallback(async () => {
    if (!selectedCompanyId || isNil(dOffset)) {
      return setWorkers([]);
    }

    setLoadingTableData(true);
    try {
      const payload: GetSiteWorkersRequestPayload = {
        offset: dOffset as number,
        limit: GRID_PAGE_SIZE,
        substring,
        order_by: formatTimeSortModel<WorkerLookupDTO>(sortModel),
        site_id: siteId,
        only_checked_in: filters?.user_status === USER_STATUSES.checked_in,
        only_not_checked_in: filters?.user_status === USER_STATUSES.not_checked_out,
        job_title: filters?.role,
      };
      const res = await getLookupSiteWorkersRequest({ companyId: selectedCompanyId, payload });
      setTotal(res.data.count);
      setWorkers(res.data.items);
      setLoadingTableData(false);
    } catch (e) {
      setLoadingTableData(false);
      handleServerError(e);
    }
  }, [dOffset, sortModel, substring, selectedCompanyId, filters]);

  const addMember = async (data: AddMemberForm, employee?: Employee) => {
    if (!employee || !selectedCompanyId || !siteId) {
      return;
    }

    try {
      const worker: AddTimeWorkerData = {
        basic_amount: data.basic_amount,
        basic_unit: data.basic_unit,
        overtime_amount: data.overtime_amount,
        overtime_unit: data.overtime_unit,
        user_id: employee.user_id,
      };
      await addSiteWorkerRequest({
        companyId: selectedCompanyId,
        siteId,
        shiftId: data.shift_id,
        worker,
      });
      closeAddMemberDialog();
      getMembers();
    } catch (e) {
      handleServerError(e);
    }
  };

  const formatFilters = (filters: any) => {
    let formattedFilters = { ...filters };
    Object.keys(formattedFilters).forEach(key => {
      const filterItem = formattedFilters[key];
      if (!filterItem) {
        delete formattedFilters[key];
      }
      if (isJson(filterItem)) {
        formattedFilters[key] = JSON.parse(filterItem);
      }
    });

    return formattedFilters;
  };

  useEffect(() => {
    if (history.location.search) {
      const { page, ...filters } = queryString.parse(history.location.search, { parseNumbers: true });
      const formattedFilters = formatFilters(filters);

      setFilters(formattedFilters);
    } else {
      setFilters({});
    }
  }, [history.location.search]);

  useEffect(() => {
    if (isSitePage && siteId) {
      getMembers();
    } else if (!isSitePage) {
      getMembers();
    }
  }, [siteId, isSitePage, getMembers]);

  useEffect(() => {
    fetchJobs();
  }, []);

  return {
    total,
    workers,
    substring,
    sortModel,
    isAddMemberDialogOpen,
    loadingTableData,
    filtersSections,
    filters,
    handleFilterChange,
    setOffset,
    handlePageChange,
    handleSearchChange,
    addMember,
    getMembers,
    handleSortModelChange,
    openAddMemberDialog,
    closeAddMemberDialog,
    removeWorkerFromAllLocations,
  };
};
