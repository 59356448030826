import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { UserPersonalInfo } from '@vyce/core/src/views/profile/user/UserPersonalInfo';
import { WorkerProfessionalInfo } from '@vyce/core/src/views/profile/user/WorkerProfessionalInfo';
import { Qualifications, RolesModule } from '@vyce/core/src/modules';

import {
  CISStatusData,
  Employee,
  UpdateUser,
  UpdateWorkerData,
  UploadAvatarData,
  UserPreviewData,
} from '@vyce/core/src/types';
import { getUserDataRequest, updateUserRequest } from '@vyce/core/src/api/legend/users';
import { updateWorkerRequest } from '@vyce/core/src/api/legend/workers';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { EmploymentInfo } from '@vyce/core/src/views/profile/user/EmploymentInfo';
import { Agreements } from '@vyce/core/src/views/profile/user/Agreements';
import {
  downloadAgreementRequest,
  downloadHealthAndSafetyPDFRequest,
  downloadPayslipsRequestByLegend,
  getAgreementsRequest,
  getEmployeeTimesheetsRequest,
  getPayslipsPDFRequest,
  getUserCisRequest,
  searchPayslipsRequest,
  updateEmployeeRequest,
} from '@vyce/core/src/api/legend/pay';
import { getTagListRequest } from '@vyce/core/src/api/legend/handbook';
import { Documents } from '@vyce/core/src/views/profile/user/Documents';
import {
  createCustomQualificationRequest,
  deleteCustomQualificationRequest,
  getQualificationsRequest,
  getRtwRequest,
  getUserCheckersRequest,
  triggerCheckerRequest,
  updateCustomQualificationRequest,
  uploadCustomQualificationImageRequest,
  updateRtwRequest,
  deleteCustomQualificationImageRequest,
  hideListedQualificationRequest,
} from '@vyce/core/src/api/legend/checkers';
import { saveAvatarRequest } from '@vyce/core/src/api/legend/storage';
import {
  uploadPassportRequest,
  uploadSelfieRequest,
  getPassportRequest,
} from '@vyce/core/src/api/legend/documents';
import { HealthAndSafety } from '@vyce/core/src/views/profile/user/H&S';
import { saveSurveyAnswers } from '@vyce/core/src/api/legend/surveys';
import { EmployeePayments } from '@vyce/core/src/views/payroll/components/EmployeePayments';
import { EmployeeTimesheets } from '@vyce/core/src/views/payroll/components/EmployeeTimesheets';
import { changePasswordByLegendRequest } from '@vyce/core/src/api/auth';
import { useBooleanState } from '@vyce/core/src/hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks';

export const RouteLegendUserProfileComponent: React.FC = () => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const [userData, setUserData] = useState<UserPreviewData>();
  const [cisStatus, setCISStatus] = useState<CISStatusData>();
  const { helper } = useTypedSelector(state => state);
  const { is_superuser } = useTypedSelector(state => state.user);
  const { access_token } = useTypedSelector(state => state.helper);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);

  const userId = useMemo(() => {
    const urlItems = getUrlItems(id);
    return urlItems?.id;
  }, [id]);

  const getCISStatus = useCallback(async () => {
    try {
      const res = await getUserCisRequest(userId);
      setCISStatus(res.data.items[0]);
    } catch (e) {}
  }, [access_token, userId]);

  const getUser = useCallback(async () => {
    try {
      const res = await getUserDataRequest({ userId });
      setUserData(res.data);
    } catch (e) {
      handleServerError(e);
    }
  }, [userId]);

  const updateUser = async ({ data }: UpdateUser) => {
    try {
      setLoadingTrue();
      await updateUserRequest({ token: helper.access_token, id: userId, data });
      await getUser();
      setLoadingFalse();
      showNotification({ message: 'User has been updated', options: { variant: 'success' } });
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const uploadUserAvatar = async ({ file, saveToProfile, handleServerError }: UploadAvatarData) => {
    const res = await saveAvatarRequest(file, helper.access_token, userData?.user.uuid as string);
    const photo = res.data;
    const data = { photo };
    await updateUser({ data, showNotification, handleServerError });
  };

  const updateWorker = async ({ showNotification, data, handleServerError }: UpdateWorkerData) => {
    const workerId = userData?.user?.worker?.uuid;
    if (!workerId) {
      return;
    }
    setLoadingTrue();
    try {
      await updateWorkerRequest({ token: helper.access_token, workerId, data });
      await getUser();
      setLoadingFalse();
      showNotification({ message: 'User has been updated', options: { variant: 'success' } });
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const updateEmployee = async (data: Employee, employeeId: string) => {
    setLoadingTrue();
    try {
      await updateEmployeeRequest(data, employeeId);
      await getUser();
      setLoadingFalse();
      showNotification({ message: 'User has been updated', options: { variant: 'success' } });
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      getCISStatus();
    }
    return () => {
      mounted = false;
    };
  }, [id]);

  if (!userData) {
    return null;
  }

  switch (tab) {
    case 'personal':
      return (
        <UserPersonalInfo
          changePasswordRequest={changePasswordByLegendRequest}
          updateUser={updateUser}
          uploadUserAvatar={uploadUserAvatar}
          token={helper.access_token}
          userDataLoading={loading}
          isLegend={true}
          user={userData.user}
        />
      );
    case 'professional':
      return (
        <WorkerProfessionalInfo
          jobTitle={userData.user?.employee?.job_title as string}
          userCity={userData.user?.address?.address_line_3}
          userDataLoading={loading}
          updateWorker={updateWorker}
          helper={helper}
          worker={userData.user?.worker}
        />
      );
    case 'employment':
      return (
        <EmploymentInfo
          userDataLoading={loading}
          isLegend={is_superuser}
          token={access_token}
          updateEmployee={updateEmployee}
          helper={helper}
          cisStatus={cisStatus}
          employee={userData.user?.employee}
        />
      );
    case 'documents':
      return (
        <Documents
          getUser={getUser}
          isLegend={true}
          updateRTWRequest={updateRtwRequest}
          getRtwRequest={getRtwRequest}
          uploadSelfieRequest={uploadSelfieRequest}
          uploadPassportRequest={uploadPassportRequest}
          triggerCheckerRequest={triggerCheckerRequest}
          getPassportRequest={getPassportRequest}
          user={userData.user}
        />
      );
    case 'qualifications':
      return (
        <Qualifications
          getCheckersRequest={getUserCheckersRequest}
          triggerCheckerRequest={triggerCheckerRequest}
          getQualificationsRequest={getQualificationsRequest}
          userId={userData.user?.uuid}
          isAdmin
          createCustomQualificationRequest={createCustomQualificationRequest}
          updateCustomQualificationRequest={updateCustomQualificationRequest}
          deleteCustomQualificationRequest={deleteCustomQualificationRequest}
          uploadCustomQualificationImageRequest={uploadCustomQualificationImageRequest}
          deleteCustomQualificationImageRequest={deleteCustomQualificationImageRequest}
          getTagListRequest={getTagListRequest}
          hideListedQualificationRequest={hideListedQualificationRequest}
        />
      );
    case 'medical':
      return (
        <HealthAndSafety
          downloadHealthAndSafetyPDFRequest={downloadHealthAndSafetyPDFRequest}
          setSurvey={getUser}
          userId={userData?.user?.uuid}
          survey={userData?.surveys?.find(item => item.required)}
          saveSurveyAnswers={saveSurveyAnswers}
        />
      );
    case 'payments':
      return (
        <EmployeePayments
          getPayslipsPDFRequest={getPayslipsPDFRequest}
          downloadUserPayslipsRequest={downloadPayslipsRequestByLegend}
          searchPayslipsRequest={searchPayslipsRequest}
          isLegend
          unit="payments"
        />
      );
    case 'timesheets':
      return (
        <EmployeeTimesheets
          getEmployeeTimesheetsRequest={getEmployeeTimesheetsRequest}
          token={access_token}
          unit="timesheets"
        />
      );
    case 'agreements':
      return (
        <Agreements
          userId={userData.user?.uuid}
          downloadAgreementRequest={downloadAgreementRequest}
          getAgreementsRequest={getAgreementsRequest}
          token={access_token}
          unit="agreements"
        />
      );

    case 'roles':
      return <RolesModule userId={userData.user?.uuid} mode="preview" />;
    default:
      return <></>;
  }
};
