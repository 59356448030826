import { Certificate, Course, TagGroups, User } from '../../../types';

export const getPersonalizedMessage = (user: User) => {
  const positions = user.positions;

  if (positions?.length) {
    return 'You are a helpful assistant, who can help with finding information about a worker and about workers who are currently clocked in or clocked out.';
  }

  const employee = user.employee;
  let message = `You are a helpful assistant, let us tell you more about user who gonna ask you. Address: ${user.address?.address_snippet}. Date of Birth: ${user.date_of_birth}. `;

  if (employee?.job_title) {
    message += `Job title(s): ${employee?.job_title}. `;
  }

  if (user.nationality?.name) {
    message += `Nationality: ${user.nationality.name}. `;
  }

  const roles = user.worker?.tags?.filter(tag => tag.group === TagGroups.ROLE);
  if (roles?.length) {
    message += `Worked as ${roles.map(role => `${role.name} `)}. `;
  }

  const skills = user.worker?.tags?.filter(tag => tag.group === TagGroups.SKILL);
  if (skills?.length) {
    message += `Skills: ${skills.map(skill => ` ${skill.name}`)}. `;
  }

  const certificates = user.qualifications?.certificates?.items;
  if (certificates?.length) {
    message += `Certificates: ${certificates.map((item: Certificate) => ` ${item.title}`)}`;
  }

  const courses = user.qualifications?.courses?.items;
  if (courses?.length) {
    message += `Courses: ${courses.map((item: Course) => ` ${item.title}`)}`;
  }

  const tests = user.qualifications?.tests?.items;
  if (tests?.length) {
    message += `Tests: ${tests.map((item: Course) => ` ${item.title}`)}`;
  }

  return (message +=
    'Provide answers that are more consistent with these data. Remember that you will chat with this user.');
};
