import React from 'react';

import AIChatContextProvider from './context';
import { AIChat } from './AIChat';
import { User } from '../../types';
import config from '../../api/config';

interface Props {
  user?: User;
  model?: string;
  companyId?: string;
}

export const AITool: React.FC<Props> = ({ user, model, companyId }) => {
  const assistantId = config.docAnalystAssistantId;

  return (
    <AIChatContextProvider user={user} model={model} assistantId={assistantId} companyId={companyId}>
      <AIChat />
    </AIChatContextProvider>
  );
};
