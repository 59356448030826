import React, { useContext, useEffect, useRef } from 'react';

import { Box, useTheme } from '@material-ui/core';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi2';
import { HiOutlineLightBulb } from 'react-icons/hi';

import { GetStarted } from './components/GetStarted';
import { AI_TOOL_ACTIVATED } from './constants';
import { AIChatContext } from './context';
import { Message } from './components/Message';
import { AskAIInput } from './components/AskAIInput';
import { IdeasDialog } from './components/IdeasDialog';
import { TipsDialog } from './components/TipsDialog';
import { DeviceContext } from '../../contexts';
import { useBooleanState } from '../../hooks';
import { getFromLS } from '../../utils/local-storage';
import { AnimatedButton } from '../../components/AnimatedButton';
import { RouterPrompt } from '../../components';
import { NotificationContext } from '../../contexts/notificationContext';
import { WelcomeMessage } from './components/WelcomeMessage';
import VyceLoader from '../../components/VyceLoader';

export const AIChat: React.FC = () => {
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);
  const { activateAIChat, isAIActivated, messages, setIsAIActivated, files, ask, loading } =
    useContext(AIChatContext);
  const { showNotification } = useContext(NotificationContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [openTipsDialog, setOpenTipsDialog, setCloseTipsDialog] = useBooleanState(false);
  const [openIdeasDialog, setOpenIdeasDialog, setCloseIdeasDialog] = useBooleanState(false);

  const requestMicrophoneAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      showNotification({
        message: 'Microphone access denied',
        options: { variant: 'warning' },
      });
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const status = getFromLS(AI_TOOL_ACTIVATED);
    setIsAIActivated(!!status);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (isAIActivated) {
      requestMicrophoneAccess();

      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
    };
  }, [isAIActivated]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.role === 'user') {
      ask(messages);
    }
  }, [messages]);

  if (!isAIActivated) {
    return <GetStarted onStartClick={activateAIChat} />;
  }

  const buttons = (
    <>
      <AnimatedButton
        expanded={isMobile}
        expandedWidth={isMobile ? 110 : 90}
        title="Ideas"
        onClick={setOpenIdeasDialog}
        icon={<HiOutlineLightBulb color={theme.palette.primary.contrastText} size={20} />}
      />
      <AnimatedButton
        expanded={isMobile}
        expandedWidth={isMobile ? 110 : 107}
        title="Top Tips"
        onClick={setOpenTipsDialog}
        icon={<HiOutlineQuestionMarkCircle color={theme.palette.primary.contrastText} size={20} />}
      />
    </>
  );

  return (
    <Box display="flex" height="100%" overflow="hidden" justifyContent="center">
      {!isMobile && (
        <Box
          position="fixed"
          top={80}
          right={16}
          display="flex"
          flexDirection="column"
          gridGap={16}
          alignItems="flex-end">
          {buttons}
        </Box>
      )}

      <Box
        maxWidth={900}
        height="100%"
        paddingTop={2}
        position="relative"
        width="100%"
        display="flex"
        flexDirection="column">
        <Box display="flex" flex={1}>
          <Box
            position="absolute"
            top="40%"
            left="50%"
            display="flex"
            zIndex={0}
            alignItems="center"
            justifyContent="center"
            style={{
              transform: 'translate(-50%, -50%)',
            }}>
            {!!messages.length && loading && <VyceLoader />}
            {!messages?.length && <WelcomeMessage />}
          </Box>

          <Box
            maxHeight={`calc(100vh - ${isMobile ? 170 : 260}px)`}
            flex={1}
            zIndex={1}
            display="flex"
            padding={isMobile ? 0 : '0 16px'}
            paddingTop={isMobile ? 12 : 0}
            paddingBottom={8}
            style={{ overflowY: 'auto' }}
            flexDirection="column"
            gridGap={16}
            marginBottom={2}
            justifyContent="flex-start">
            {messages.map((message, index) => (
              <Message
                key={message.content + index}
                message={message}
                file={files?.find(file => file.fileId === message.attachments?.[0].file_id)}
              />
            ))}
            <div ref={messagesEndRef} />
          </Box>
        </Box>

        {isMobile && !messages?.length && (
          <Box
            position="fixed"
            bottom={200}
            display="flex"
            gridGap={16}
            width="100vw"
            left={0}
            justifyContent="center">
            {buttons}
          </Box>
        )}

        <AskAIInput
          attachFile
          style={{
            position: 'fixed',
            bottom: 8,
            width: 'calc(100% - 32px)',
            maxWidth: 868,
            padding: 24,
            margin: '0 16px 24px',
            border: `1px solid ${theme.palette.divider}`,
          }}
        />
      </Box>

      <IdeasDialog open={openIdeasDialog} handleClose={setCloseIdeasDialog} onIdeaClick={() => {}} />

      <TipsDialog open={openTipsDialog} handleClose={setCloseTipsDialog} />

      <RouterPrompt
        page="VyceAIChat"
        when={!!messages.length}
        title="Do you want to leave Vyce AI?"
        subtitle="We don't save your conversations, so you may want to copy and save the answer before leaving."
        okText="Leave the page"
        cancelText="Stay here"
        onOK={async () => true}
        onCancel={() => false}
      />
    </Box>
  );
};
