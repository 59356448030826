import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';

import {
  ActiveOnboardingStep,
  Image,
  ManualClockProps,
  OnboardingStep,
  Site,
  TimeAndAttendanceLocation,
} from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';
import {
  checkUserActiveCheckInRequest,
  getLookupSiteWorkersRequest,
  manualClockInOutRequest,
  getWorkerSitesRequest,
  getWorkerShiftsRequest,
} from '@vyce/core/src/api/time';
import { ActiveCheckInDTO, GetSiteWorkersRequestPayload, WorkerLookupDTO } from '@vyce/core/src/api/types';

import { Action, Props, TransformedShift } from '../types';
import { clockOutHiddenSteps, defaultSteps, defaultValues, stepNames } from '../config';
import { filterShifts, prepareData } from '../utils';
import { siteShiftToShift, siteToLocation } from '../../timeModule/utils';
import { NotificationContext } from '../../../contexts/notificationContext';

const payload: GetSiteWorkersRequestPayload = {
  offset: 0,
  limit: 3000,
  order_by: [{ field: 'first_name', desc: true }],
  site_id: undefined,
};

const defaultLocations: TimeAndAttendanceLocation[] = [];

export const useManualClockData = ({ /* locations, */ selectedCompanyId }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>({ step: defaultSteps[1], number: 1 });
  const [steps, setSteps] = useState<OnboardingStep[]>(defaultSteps);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [image, setImage] = useState<Image>();
  const [clockInTimestamp, setClockInTimestamp] = useState<string | null>(null);
  const [activeCheckInInfo, setActiveCheckInInfo] = useState<ActiveCheckInDTO>();
  const [shifts, setShifts] = useState<TransformedShift[]>();
  const [workers, setWorkers] = useState<WorkerLookupDTO[]>([]);
  const [clockedWorkerName, setClockedWorkerName] = useState('');
  const [previousAction, setPreviousAction] = useState<Action>('in');
  const [locations, setLocations] = useState<TimeAndAttendanceLocation[]>([]);

  const [isDialogOpen, setDialogOpen, setDialogClose] = useBooleanState(false);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen, setSuccessDialogClose] = useBooleanState(false);

  const childRef = useRef<any>();

  const methods = useForm<ManualClockProps>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const filteredSteps = useMemo(() => steps.filter(item => item.active), [steps]);
  const { setValue, watch, reset } = methods;
  const action = watch('action');
  const withPhoto = watch('withPhoto');
  const timestamp = watch('timestamp');
  const verifiedLocation = watch('verifiedLocation');
  const location = watch('location');
  const selectedWorker = watch('selectedWorker');

  const nextStep = (jumpTo?: number) => {
    childRef?.current?.nextStep(jumpTo);
  };

  const getWorkerShifts = async (companyId: string, userId: string, siteId: string) => {
    try {
      setLoadingTrue();
      const res = await getWorkerShiftsRequest({ companyId, userId, siteId });
      const locationShifts = res.data.items?.map(item => siteShiftToShift(item));
      const filteredShifts = filterShifts(locationShifts, timestamp);
      if (filteredShifts.length === 0) setDisableNext(true);
      setShifts(filteredShifts);
      setLoadingFalse();
    } catch (e) {
      setShifts([]);
      setLoadingFalse();
      console.error(e);
    }
  };

  const createClockInOut = async (data: ManualClockProps) => {
    if (!selectedCompanyId || !data?.selectedWorker?.id) {
      return;
    }

    const payload = prepareData(data, activeCheckInInfo);
    try {
      await manualClockInOutRequest({
        companyId: selectedCompanyId,
        userId: data?.selectedWorker?.id,
        payload,
      });
      await getMembers();

      setClockedWorkerName(selectedWorker?.name ?? '');
      setPreviousAction(action);
      setSuccessDialogOpen();
      setActiveStep({ step: defaultSteps[1], number: 1 });
      reset();
    } catch (e) {
      handleServerError(e);
    }
  };

  const fetchWorkerSitesRequest = async () => {
    if (!selectedCompanyId || !selectedWorker?.id) {
      return;
    }

    try {
      const { data } = await getWorkerSitesRequest({
        companyId: selectedCompanyId,
        userId: selectedWorker?.id,
        payload: {
          offset: 0,
          limit: 100,
          order_by: [
            {
              field: 'updated_at',
              desc: true,
            },
          ],
        },
      });
      setLocations(data.items.map((item: Site) => siteToLocation(item)));
    } catch (e) {
      handleServerError(e);
    }
  };

  const fetchUserCheckInTimeRequest = async () => {
    if (!selectedCompanyId || !selectedWorker?.id) {
      return;
    }

    try {
      const { data } = await checkUserActiveCheckInRequest({
        companyId: selectedCompanyId,
        userId: selectedWorker?.id,
      });
      setClockInTimestamp(data.timestamp);
      setActiveCheckInInfo(data);
    } catch (e) {}
  };

  const getMembers = async () => {
    if (!selectedCompanyId) {
      return setWorkers([]);
    }

    setLoadingTrue();
    try {
      const res = await getLookupSiteWorkersRequest({
        companyId: selectedCompanyId,
        payload: { ...payload, only_checked_in: action === 'out', only_not_checked_in: action === 'in' },
      });
      setWorkers(res.data.items);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const handleNext = async (data: ManualClockProps) => {
    switch (activeStep.number) {
      case filteredSteps.length - 1:
        createClockInOut(data);
        break;
      case 2:
        if (data.selectedWorker) {
          return nextStep(3);
        }
        break;
      case 3:
        if (data.location && shifts?.length) {
          return nextStep();
        }
        break;
      case 4:
        if (data.location) {
          return nextStep();
        }
        break;
      default:
        nextStep();
        break;
    }
  };

  const onChangeAction = useCallback((action: Action) => {
    setValue('action', action);
    setDisableNext(false);
  }, []);

  const onChangeDate = (date?: Date | 'now' | null) => {
    if (date) setValue('timestamp', date);
  };

  const onChangeImage = useCallback((newImage: Image) => {
    setImage(newImage);
  }, []);

  const onChangeVerifiedLocation = useCallback((verified: boolean) => {
    setValue('verifiedLocation', verified);
  }, []);

  const onChangePhotoFlag = useCallback(
    (photoFlag: boolean) => {
      setValue('withPhoto', photoFlag);
      setDisableNext(false);
    },
    [activeStep]
  );

  useEffect(() => {
    setSteps(steps =>
      steps.map(item => {
        return item.name === stepNames.UPLOAD_PICTURE_STEP
          ? {
              ...item,
              active: withPhoto,
            }
          : item;
      })
    );
  }, [withPhoto]);

  useEffect(() => {
    setValue('shift', undefined);
    if (location) nextStep(3);
    setDisableNext(false);
    if (location?.uuid && location.shifts && selectedCompanyId && selectedWorker?.id) {
      getWorkerShifts(selectedCompanyId, selectedWorker.id, location.uuid);
    }

    setShifts([]);
  }, [location]);

  useEffect(() => {
    setLocations(defaultLocations);
    fetchUserCheckInTimeRequest();
    fetchWorkerSitesRequest();
  }, [selectedWorker?.id]);

  // observe action change and turn on or of some steps
  useEffect(() => {
    if (action === 'out' && activeStep.number > 3) {
      setValue('location', undefined);
      nextStep(2);
    }
    setSteps(steps =>
      steps.map(item => {
        return clockOutHiddenSteps.includes(item.name)
          ? {
              ...item,
              active: action === 'in',
            }
          : item;
      })
    );
  }, [action]);

  useEffect(() => {
    if (shifts?.length === 1) {
      setValue('shift', shifts[0]);
      if (activeStep.step.name === stepNames.SHIFT_STEP) {
        const step = steps.find(step => step.name === stepNames.LOCATION_VERIFIED_STEP);
        if (step) {
          setActiveStep({ step, number: activeStep.number - 1 });
        }
      }
    }

    setSteps(steps =>
      steps.map(item => {
        return item.name === stepNames.SHIFT_STEP
          ? {
              ...item,
              active: shifts?.length !== 1 && action === 'in',
            }
          : item;
      })
    );
  }, [shifts, action]);

  useEffect(() => {
    getMembers();
  }, [selectedCompanyId, action]);

  const contextOption = useMemo(
    () => ({
      action,
      locations,
      withPhoto,
      image,
      timestamp,
      verifiedLocation,
      workers,
      shifts,
      nextStep,
      setDialogOpen,
      onChangeDate,
      onChangePhotoFlag,
      onChangeImage,
      onChangeAction,
      onChangeVerifiedLocation,
      loading,
    }),
    [
      action,
      locations,
      verifiedLocation,
      shifts,
      workers,
      timestamp,
      withPhoto,
      image,
      onChangePhotoFlag,
      loading,
    ]
  );

  return {
    disableNext,
    activeStep,
    isDialogOpen,
    action,
    timestamp,
    methods,
    filteredSteps,
    childRef,
    contextOption,
    clockInTimestamp,
    clockedWorkerName,
    isSuccessDialogOpen,
    previousAction,
    setSuccessDialogClose,
    handleNext,
    setActiveStep,
    setDialogClose,
    onChangeDate,
  };
};
