import React, { useCallback } from 'react';

import { GridCellWithAvatar } from '@vyce/core/src/components';
import { getTime } from '@vyce/core/src/utils/dates';
import { getAvatar } from '@vyce/core/src/utils';
import { WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { WidgetTemplate } from './WidgetTemplate';
import { Props } from './types';
import { EnrichedColumnProps } from '../../../components/InfiniteTable/types';
import { CheckOutWorkerDTO } from '../../../api/types';

export const ClockOutWidget = (props: Props & WidgetWrapperProps) => {
  const getColumns = useCallback(
    (cnaViewWorker: boolean): EnrichedColumnProps<CheckOutWorkerDTO>[] => [
      {
        field: 'first_name',
        headerName: 'Name',
        flex: 1,
        width: 150,
        sortable: true,
        renderCell: row => (
          <GridCellWithAvatar
            avatarUrl={row.photo_url}
            avatarPlaceholder={getAvatar(row.gender)}
            link={cnaViewWorker ? `/time/users/${row.first_name}_${row.user_id}/logs` : ''}
            name={`${row.first_name} ${row.last_name}`}
          />
        ),
      },
      {
        field: 'role',
        headerName: 'Role',
        flex: 0.8,
        width: 100,
        sortable: true,
      },
      {
        field: 'site_name',
        headerName: 'Location',
        flex: 0.8,
        width: 100,
        sortable: true,
      },

      {
        field: 'checked_out',
        headerName: 'Clocked out',
        flex: 0.8,
        width: 100,
        sortable: true,
        valueGetter: row => getTime(row.checked_out),
      },
    ],
    []
  );

  return <WidgetTemplate getColumns={getColumns} {...props} type="clock_out" />;
};
