import { useCallback, useContext, useEffect, useState } from 'react';

import { ComputedDatum } from '@nivo/pie';
import { useHistory } from 'react-router-dom';

import { getOnSiteTradesDataRequest, getCompaniesWidgetsSharedData } from '@vyce/core/src/api/time';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';

import { Props, WidgetDataItem } from '../types';
import { NotificationContext } from '../../../../contexts/notificationContext';
import { generateExcelFileLink } from '../../../../utils';
import { useBooleanState } from '../../../../hooks';

export const useData = ({ selectedCompanyId }: Props) => {
  const [loading, setLoading] = useState(false);
  const [downloadButtonLoading, setDownloadButtonLoadingTrue, setDownloadButtonLoadingFalse] =
    useBooleanState(false);
  const { handleServerError } = useContext(NotificationContext);
  const [widgetData, setWidgetData] = useState<WidgetDataItem[]>([]);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await getOnSiteTradesDataRequest(selectedCompanyId);

      const preparedData = data.trades.map(item => ({
        id: item.trade,
        label: item.trade,
        value: item.count,
        query_field: { role: item.trade },
      }));
      setWidgetData(preparedData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId]);

  const getSharedFile = async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      setDownloadButtonLoadingTrue();
      const res = await getCompaniesWidgetsSharedData({
        companyId: selectedCompanyId,
        target: 'on_site_trades',
        payload: {},
      });
      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    } finally {
      setDownloadButtonLoadingFalse();
    }
  };

  const handleChartClick = (datum: ComputedDatum<any>) => {
    const query = generateQueryParamsFromObj(datum.data.query_field);
    history.push(`/time/users?${query}&user_status=Checked in`);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { widgetData, loading, downloadButtonLoading, getSharedFile, handleChartClick };
};
