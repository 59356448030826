import React, { useMemo } from 'react';

import { Box, Typography, MenuItem, Button, useTheme } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import { FormProvider } from 'react-hook-form';
import noop from 'lodash/noop';
import { RiListSettingsLine } from 'react-icons/ri';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { CgArrowsExpandRight } from 'react-icons/cg';
import { IoSearchSharp } from 'react-icons/io5';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { AppDataGrid, AppIconButton } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { FiltersDialog } from '@vyce/core/src/components/Dashboard/FiltersDialog';
import { AppSearchInput } from '@vyce/core/src/components/inputs/AppSearchInput';
import { ExpandedMenuButton, StyledBadge } from '@vyce/core/src/components/Dashboard/ExpandedMenuButton';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { useStyles } from './styles';
import { AppGroupedSelect } from '../../../components/inputs/AppGroupedSelect';
import { InfiniteTable } from '../../../components/InfiniteTable';
import { EnrichedColumnProps } from '../../../components/InfiniteTable/types';

/* Clocked in/out widget */

export const WidgetTemplate = <T,>({
  getColumns,
  ...restProps
}: Props &
  WidgetWrapperProps & {
    getColumns: (cnaViewWorker: boolean) => EnrichedColumnProps<T>[];
  }) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    workers,
    selectedLocationOptions,
    unselectedLocationOptions,
    total,
    sortModel,
    methods,
    filterSettings,
    isFilterDialogOpen,
    cnaViewWorker,
    loading,
    isMobile,
    isSmallView,
    isShowSearchField,
    isFilterWasActivated,
    downloadButtonLoading,
    isLoadingList,
    getRoles,
    resetLoadingList,
    getSharedFile,
    onSubmit,
    restoreFilters,
    openFilterDialog,
    closeFilterDialog,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange,
    setSmallView,
    setShowSearchFieldTrue,
  } = useWidgetData<T>(restProps);

  const columns = useMemo(() => getColumns(cnaViewWorker), [cnaViewWorker, getColumns]);

  const bigHeaderView = useMemo(
    () => (
      <>
        <AppSearchInput
          onChange={handleSearchChange}
          isExtraSmall
          isBorder
          smallRounding
          expanded={!isMobile}
        />

        <StyledBadge color="primary" variant="dot" invisible={!isFilterWasActivated}>
          <AppIconButton variant="paper" isSmall isBorder onClick={openFilterDialog}>
            <RiListSettingsLine size="20px" color={theme.palette.text.primary} />
          </AppIconButton>
        </StyledBadge>

        <AppIconButton
          variant="paper"
          isSmall
          isBorder
          onClick={getSharedFile}
          disabled={downloadButtonLoading}>
          <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
        </AppIconButton>
      </>
    ),
    [isMobile, handleSearchChange, isFilterWasActivated, downloadButtonLoading, openFilterDialog]
  );

  return (
    <WidgetWrapper
      {...restProps}
      withExpandButton={!isSmallView}
      setSmallViewStatus={(value: boolean) => {
        setSmallView(value);
      }}
      renderExtraControls={({ handleFullscreenStatusChange }) => (
        <>
          {isSmallView ? (
            <ExpandedMenuButton hideBadge={!isFilterWasActivated}>
              <>
                <Button
                  onClick={handleFullscreenStatusChange}
                  endIcon={<CgArrowsExpandRight size="16px" />}
                  variant="outlined"
                  size="small"
                  hidden={isMobile}>
                  Expanded
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<RiDownloadCloud2Line size="16px" />}
                  onClick={getSharedFile}>
                  Download
                </Button>

                {!isShowSearchField && (
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<IoSearchSharp size="16px" />}
                    onClick={setShowSearchFieldTrue}>
                    Search
                  </Button>
                )}

                <StyledBadge color="primary" variant="dot" invisible={!isFilterWasActivated}>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<RiListSettingsLine size="16px" />}
                    onClick={openFilterDialog}>
                    Filter
                  </Button>
                </StyledBadge>
              </>
            </ExpandedMenuButton>
          ) : (
            bigHeaderView
          )}
        </>
      )}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(noop)} className={classes.formContainer}>
          <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={12}>
            <Box width="100%" display="flex" flexDirection="column" gridGap={12}>
              {isSmallView && isShowSearchField && (
                <Box width="200px">
                  <AppSearchInput onChange={handleSearchChange} isSmall isBorder expanded={!isMobile} />
                </Box>
              )}
              <Box width="100%" display="flex" gridGap={12} alignItems="center">
                <Typography color="primary" className={classes.count}>
                  {total} workers
                </Typography>
                <Typography>on</Typography>
                <AppGroupedSelect
                  groupOneOptions={selectedLocationOptions}
                  groupTwoOptions={unselectedLocationOptions}
                  name="location"
                />
              </Box>
            </Box>

            <InfiniteTable
              data={workers}
              columns={columns}
              rowCount={total}
              rowHeight={54}
              height="calc(100% - 78px)"
              pageSize={GRID_PAGE_SIZE}
              isLoadingList={isLoadingList}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              onLoadMore={getRoles}
              resetLoadingList={resetLoadingList}
            />

            <FiltersDialog
              isOpen={isFilterDialogOpen}
              onClose={closeFilterDialog}
              filterSettings={filterSettings}
              onSubmit={onSubmit}
              restoreFilters={restoreFilters}
              loading={loading}
            />
          </Box>
        </form>
      </FormProvider>
    </WidgetWrapper>
  );
};
