import React, { useContext } from 'react';

import { WorkerDetails } from '@vyce/core/src/views/time/WorkerDetails';
import { TimeModuleContext } from '@vyce/core/src/contexts';
import { LegendUserPreviewProvider } from '@vyce/core/src/contexts/legendUserPreviewProvider';
import { useEmployeeProfileData } from '@vyce/core/src/modules/userPreviewModule/hooks/useEmployeeProfileData';
import { getRtwRequest } from '@vyce/core/src/api/legend/checkers';
import { getPassportRequest } from '@vyce/core/src/api/legend/documents';
import { getUserDataRequest } from '@vyce/core/src/api/legend/users';

import { useTypedSelector } from '../../hooks';

export const LegendWorkerDetails: React.FC = () => {
  const { locations } = useContext(TimeModuleContext);
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { userPreviewData, rtw, passport, userPreviewDataLoading, contextOptions } = useEmployeeProfileData({
    getEmployeePreviewRequest: getUserDataRequest,
    getPassportRequest,
    getRtwRequest,
  });

  return (
    <LegendUserPreviewProvider value={contextOptions}>
      <WorkerDetails
        loading={userPreviewDataLoading}
        companyId={selectedCompany?.uuid}
        locations={locations || []}
        rtw={rtw}
        passport={passport}
        userPreviewData={userPreviewData}
        isLegend={true}
      />
    </LegendUserPreviewProvider>
  );
};
